import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constantes from '../../tools/constantes';

@Injectable({
  providedIn: 'root'
}) 
export class Sesion_Admin {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  
  constructor(private httpClient: HttpClient) {
  }

  public autenticar(){
    return this.httpClient.get(Constantes.apiserver+"admin/sesion/autenticar");
  }

  public logout(){
    return this.httpClient.get(Constantes.apiserver+"admin/sesion/logout");
  }

  public recuperar(){
    return this.httpClient.get(Constantes.apiserver+"admin/sesion/recuperar");
  }

  private call(data, metodo){
    return this.httpClient.post(
      Constantes.apiserver+metodo, 
      data, 
      this.httpOptions
    );
  }

}