import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constantes from '../../tools/constantes';

@Injectable({
  providedIn: 'root'
})
export class Usuario_Admin {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  
  constructor(private httpClient: HttpClient) {
  }

  public editar(array_usuario){
    return this.call(array_usuario, "admin/usuario/edit");
  }

  public editarUsuarios(id_usuario,array_usuario){
    return this.call(array_usuario, "admin/usuario/editUsuarios/"+id_usuario);
  }

  // public historial(pagina, buscar){
  //   return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/historial/"+pagina+"/"+buscar);
  // }

  public listar(pagina, buscar){
    return this.httpClient.get(Constantes.apiserver+"admin/usuario/listar/"+pagina+"/"+buscar);
  }
 
  public get(id_usuario){
    return this.httpClient.get(Constantes.apiserver+"admin/usuario/get/"+id_usuario);
  }

  public eliminar(id_usuario){
    return this.httpClient.get(Constantes.apiserver+"admin/usuario/eliminar/"+id_usuario);
  }

  private call(data, metodo){
    return this.httpClient.post(
      Constantes.apiserver+metodo, 
      data, 
      this.httpOptions
    );
  }

}