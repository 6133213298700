import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

import { faVideo, faTimesCircle, faAngleDoubleLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Comunicacion } from '../api/tools/comunicacion';
import { Videoconferencia } from '../api/service/videoconferencia';
import * as Constantes from '../api/tools/constantes';
import { strictEqual } from 'assert';

@Component({
  selector: 'app-v',
  templateUrl: './v.component.html',
  styleUrls: ['./v.component.scss']
})
export class VComponent implements OnInit {
  meetingid = this.activatedrouter.snapshot.paramMap.get('meetingid');
  meetingid_correcto = true;
  sin_acceso = false;
  mostrar = false;
  faVideo = faVideo;
  faTimesCircle = faTimesCircle;
  error = '';
  v : any;
  tiempo_agregado = 0;
  url = '';
  habilitar_formulario = false;
  Form: FormGroup;
  submitted = false;
  form_error = "";
  faAngleDoubleLeft = faAngleDoubleLeft;
  moderador = false;
  moderador_error = false;
  moderador_error_length = false;
  tiempo_activador = 0;
  ver_conferencia = false;
  faEye = faEye;
  param_nombres_apellidos = "";

  constructor(
    private activatedrouter: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private router: Router,
    private videoconferencia: Videoconferencia,
    private comunicacion: Comunicacion
  ) { }
 
  ngOnInit() {
    this.activatedrouter.queryParams.subscribe(params => {
      if(params.hasOwnProperty("nombre")){
        this.param_nombres_apellidos = params.nombre;
      }else if(params.hasOwnProperty("nombres")){
        this.param_nombres_apellidos = params.nombres;
      }
      if(params.hasOwnProperty("apellido")){
        this.param_nombres_apellidos = this.param_nombres_apellidos=="" ? params.apellido : this.param_nombres_apellidos + " " + params.apellido;
      } else if(params.hasOwnProperty("apellidos")){
        this.param_nombres_apellidos = this.param_nombres_apellidos=="" ? params.apellidos : this.param_nombres_apellidos + " " + params.apellidos;
      }
    });
    
    this.cargar_informacion();

    this.Form = this.formBuilder.group({
      moderador: ['', null]
    });
  }

  get f() { return this.Form.controls; }
  
  cargar_informacion(){
    this.videoconferencia.get_meeting(this.meetingid).subscribe((data)=>{
      if(data['rol_user']=='administrador'){
        this.comunicacion.mostrar_menu_administracion(true);
        this.comunicacion.mostrar_menu_admin(true);
      }else if(data['rol_user']=='usuario'){
        this.comunicacion.mostrar_menu_administracion(true);
        this.comunicacion.mostrar_menu_admin(false);
      }else{
        this.comunicacion.mostrar_menu_administracion(false);
        this.comunicacion.mostrar_menu_admin(false);
        this.comunicacion.mostrar_menu_super_admin(false);
        this.router.navigate(['login/'+this.meetingid]);
      }
      if(data['resultado']=="not valid"){
        //meetingid sintaxis incorrecta
        this.meetingid_correcto = false;
      }else if(data['resultado']=="error"){
        //sucedio un error
        this.sin_acceso = true;
        this.error = "Ha sucedido un error, por favor intentalo de nuevo.";
      }else if(data['resultado']=="finished"){
        this.sin_acceso = true;
        this.error = "La videoconferencia no existe o ya finalizó.";
      }else if(data['resultado']=="not ok"){
        this.sin_acceso = true;
        this.error = "La videoconferencia no existe o ya finalizó.";
      }else if(data['resultado']=="ok finalizado"){
        this.v = data['v'];
        this.habilitar_formulario = false;
        this.tiempo_agregado = 0;
        this.tiempo_activador = 0;
        this.moderador = false;
        this.ver_conferencia = true;
        this.mostrar = true;
      }else{ 
        this.mostrar = true;
        this.v = data['v'];
        if(data['url']==1){
          this.habilitar_formulario = true;
        }else{
          this.tiempo_agregado = data['tiempo_agregado'];
          this.tiempo_activador = data['tiempo_activador'];
          setTimeout(()=>{
            this.cargar_informacion()
          }, Constantes.frecuencia_actualizacion * 1000);
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    this.moderador_error = false;
    this.moderador_error_length = false;

    if(this.moderador && this.Form.get('moderador').value == ""){
      this.moderador_error = true;
    }else if(this.moderador && this.Form.get('moderador').value.length < 3){
      this.moderador_error_length = true;
    }

    if (this.Form.invalid || this.moderador_error || this.moderador_error_length) {
        this.error = "Completa el formulario."
        return;
    }

    this.videoconferencia.verificar_capacidad(this.meetingid).subscribe((data)=>{
      if(data['resultado']=="not valid"){
        //meetingid sintaxis incorrecta
        this.meetingid_correcto = false;
      }else if(data['resultado']=="error"){
        //sucedio un error
        this.sin_acceso = true;
        this.error = "Ha sucedido un error, por favor intentalo de nuevo.";
      }else if(data['resultado']=="not ok"){
        this.sin_acceso = true;
        this.error = "La videoconferencia no existe o ya finalizó.";
      }else if(data['resultado']=="capacidad"){
        this.form_error = "El número de participantes llegó a su máximo.";
      }else{
        if(this.moderador){
          this.videoconferencia.entrar_videoconferencia_password(this.meetingid, this.Form.value).subscribe((data)=>{
            if(data['resultado']=="not ok"){
              this.form_error = "Por favor completa el formulario.";
            }else if(data['resultado']=="not valid"){
              this.form_error = "La videoconferencia no existe o ya finalizó.";
            }else if(data['resultado']=="not login"){
              this.form_error = "Debes iniciar sesión.";
            }else  if(data['resultado']=="password"){
              this.form_error = "La contraseña es incorrecta.";
            }else if(data['resultado']=="error"){
              this.form_error = "Error en la solicitud.";
            }else{
              this.url = this.videoconferencia.entrar_videoconferencia_token(data['token']);
              window.open(this.url, '_self');
            }
          });
        }else{
          this.url = this.videoconferencia.entrar_videoconferencia(this.meetingid);
          window.open(this.url, '_self');
        }
      }
    });

    
  }

  entrar(tipo){
    if(tipo=="moderador"){
      this.moderador = true;
    }else{
      this.moderador = false;
    }
  }

}
