import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faCheckDouble, faTimesCircle, faSearch, faCaretLeft, faCaretRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-programacion',
  templateUrl: './programacion.component.html',
  styleUrls: ['./programacion.component.scss']
})
export class ProgramacionComponent implements OnInit {
  year_s = this.activatedrouter.snapshot.paramMap.get('year');
  mes_s = this.activatedrouter.snapshot.paramMap.get('mes');
  url_back_s = this.activatedrouter.snapshot.paramMap.get('url_back');
  error = "";
  semanas = [];
  mes_texto = "";
  mes_numero : number = 1;
  mes = [];
  year_numero : number = 2019;
  listado_meses = [];
  faCheckDouble = faCheckDouble;
  faTimesCircle = faTimesCircle;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faSearch = faSearch;
  days = [];
  days_small = [];
  mes_left = false;
  faAngleDoubleLeft = faAngleDoubleLeft;
  url_back = "/admin/programar/";
  url = "";

  constructor(
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin
  ) { }

  load_months(){
		this.mes.push({"mes":"Enero", "num": 1});
		this.mes.push({"mes":"Febrero", "num": 2});
		this.mes.push({"mes":"Marzo", "num": 3});
		this.mes.push({"mes":"Abril", "num": 4});
		this.mes.push({"mes":"Mayo", "num": 5});
		this.mes.push({"mes":"Junio", "num": 6});
		this.mes.push({"mes":"Julio", "num": 7});
		this.mes.push({"mes":"Agosto", "num": 8});
		this.mes.push({"mes":"Septiembre", "num": 9});
		this.mes.push({"mes":"Octubre", "num": 10});
		this.mes.push({"mes":"Noviembre", "num": 11});
    this.mes.push({"mes":"Diciembre", "num": 12});
  }

  set_meses(incremento : number){
    var cambio = true;
    var today = new Date();  
    if(incremento==0){
      this.mes_numero = today.getMonth();
      this.year_numero = today.getFullYear();
      this.mes_left = false;
    }else if(incremento==-2){
      this.mes_left = false;
    }else{
      var old_mes_numero = this.mes_numero;
      var old_year_numero = this.year_numero;
      this.mes_numero = (Number.parseInt(String(this.mes_numero)) + Number.parseInt(String(incremento)));
      if(this.mes_numero > 11){
        this.mes_numero = 0;
        this.year_numero++;
      }else if(this.mes_numero < 0){
        this.mes_numero = 11;
        this.year_numero--;
      }
      this.mes_left = true;
      if(this.mes_numero <= today.getMonth() && this.year_numero <= today.getFullYear()){
        this.mes_numero = today.getMonth();
        this.year_numero = today.getFullYear();
        this.mes_left = false;
      }else if(this.year_numero < today.getFullYear()){
        this.year_numero = today.getFullYear();
      }
      if(old_mes_numero == this.mes_numero && old_year_numero == this.year_numero){
        cambio = false;
      }
    }
    this.mes_texto = this.mes[this.mes_numero];
    return cambio;
  }

  incrementar_mes(){
    if(this.set_meses(1)){
      this.cargar_informacion();
    }
  }
 
  decrementar_mes(){
    if(this.set_meses(-1)){
      this.cargar_informacion();
    }
  }

  cargar_informacion(){
    this.error = "";
    this.videoconferencia_admin.programadas_mes(this.year_numero, (Number.parseInt(String(this.mes_numero))+Number.parseInt(String(1)))).subscribe((data)=>{
      if(data['resultado']=="not login"){
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="ok"){
        this.semanas = data['semanas'];
        this.mes_texto = data['mes_texto'];
        this.mes_numero = data['mes_numero'];
        this.year_numero = data['year_numero'];
        this.days = data['dias'];
        this.days_small = data['dias_small'];
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }
    });
  } 

  ngOnInit() {
    if(this.year_s!="" && this.mes_s!="" && !isNaN(Number(this.year_s)) && !isNaN(Number(this.mes_s))){
      this.year_numero = Number(this.year_s);
      this.mes_numero = Number(this.mes_s);
      this.set_meses(-2);
      this.cargar_informacion();
    }else{
      this.set_meses(0);
      this.cargar_informacion();
    }
  } 
  
}
