import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class Comunicacion {
    
    @Output() change: EventEmitter<{evento: number, valor: boolean}> = new EventEmitter();

    /* mostrar menú de usuario, evento = 1 */
    mostrar_menu_administracion(mostrar) {
        this.change.emit({evento: 1, valor: mostrar});
    }

    /* mostrar menú de administrador, evento = 2 */
    mostrar_menu_admin(mostrar) {
        this.change.emit({evento: 2, valor: mostrar});
    }

    /* mostrar menú de super administrador, evento = 3 */
    mostrar_menu_super_admin(mostrar) {
        this.change.emit({evento: 3, valor: mostrar});
    }

}