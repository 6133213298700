import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/* bootstrap */
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

/* font awesome */ 
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* time picker */
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/* template */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

/* api */
import { Comunicacion } from './api/tools/comunicacion';

/* router */
import {RouterModule} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EscritorioComponent } from './user/escritorio/escritorio.component';
import { LogoutComponent } from './user/logout/logout.component';
import { PerfilComponent } from './user/perfil/perfil.component';
import { ProgramarComponent } from './admin/programar/programar.component';
import { SalaComponent } from './user/sala/sala.component';
import { FinalizadoComponent } from './finalizado/finalizado.component';
import { VComponent } from './v/v.component';
import { EditarVideoconferenciaComponent } from './admin/editar-videoconferencia/editar-videoconferencia.component';
import { NuevoUsuarioComponent } from './admin/nuevo-usuario/nuevo-usuario.component';
import { HistorialComponent } from './user/historial/historial.component';
import { VerVideoconferenciaComponent } from './user/ver-videoconferencia/ver-videoconferencia.component';
import { VerEncuestaComponent } from './user/ver-encuesta/ver-encuesta.component';
import { ProgramacionComponent } from './admin/programacion/programacion.component';
import { VerProgramacionComponent } from './admin/ver-programacion/ver-programacion.component';
import { AdministracionUsuariosComponent } from './admin/administracion-usuarios/administracion-usuarios.component';
import { AdministrarUsuarioComponent } from './admin/administrar-usuario/administrar-usuario.component';
import { EditarUsuarioComponent } from './admin/editar-usuario/editar-usuario.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    EscritorioComponent,
    LogoutComponent,
    PerfilComponent,
    ProgramarComponent,
    SalaComponent,
    FinalizadoComponent,
    VComponent,
    EditarVideoconferenciaComponent,
    NuevoUsuarioComponent,
    HistorialComponent,
    VerVideoconferenciaComponent,
    VerEncuestaComponent,
    ProgramacionComponent,
    VerProgramacionComponent,
    AdministracionUsuariosComponent,
    AdministrarUsuarioComponent,
    EditarUsuarioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      {path:'', component: HomeComponent},
      {path:'login/:meetingid', component: HomeComponent},
      {path:'registrado', component: HomeComponent},
      {path:'restablecido', component: HomeComponent},
      {path:'user/escritorio', component: EscritorioComponent},
      {path:'user/logout', component: LogoutComponent},
      {path:'user/perfil', component: PerfilComponent},
      {path:'admin/programar', component: ProgramarComponent},
      {path:'user/programado', component: EscritorioComponent},
      {path:'user/sala/:id_videoconferencia', component: SalaComponent},
      {path:'finalizado/:meetingid', component: FinalizadoComponent},
      {path:'v/:meetingid', component: VComponent},
      {path:'user/eliminado', component: EscritorioComponent},
      {path:'user/historial', component: HistorialComponent},
      {path:'admin/programacion', component: ProgramacionComponent},
      {path:'admin/programacion_ym/:year/:mes', component: ProgramacionComponent},
      {path:'admin/ver_programacion/:year/:mes/:dia', component: VerProgramacionComponent},
      {path:'admin/editar_videoconferencia/:id_videoconferencia', component: EditarVideoconferenciaComponent},
      {path:'user/videoconferencia_editada/:id_videoconferencia', component: SalaComponent},
      {path:'user/ver_videoconferencia/:id_videoconferencia', component: VerVideoconferenciaComponent},
      {path:'user/ver_encuesta/:id_videoconferencia/:id_encuesta', component: VerEncuestaComponent},
      {path:'admin/nuevo_usuario', component: NuevoUsuarioComponent},
      {path:'admin/usuario_agregado', component: NuevoUsuarioComponent},
      {path:'admin/administracion_usuarios', component: AdministracionUsuariosComponent},
      {path:'admin/administrar_usuario/:id_usuario', component: AdministrarUsuarioComponent},
      {path:'admin/editar_usuario/:id_usuario', component: EditarUsuarioComponent},
      {path:'admin/usuario_eliminado', component: AdministracionUsuariosComponent},
      {path:'admin/usuario_editado/:id_usuario', component: AdministrarUsuarioComponent},
    ]), 
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule 
  ],
  providers: [Comunicacion],
  bootstrap: [AppComponent]
})
export class AppModule { }
