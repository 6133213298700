import { Component, OnInit } from '@angular/core';
import { faVideo, faUserPlus, faLock } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Comunicacion } from '../api/tools/comunicacion';
import { Sesion_Admin } from '../api/service/admin/sesion';
import { Sesion } from '../api/service/sesion';

@Component({
  selector: 'app-home', 
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  meetingid = this.activatedrouter.snapshot.paramMap.get('meetingid');
  faVideo = faVideo;
  faUserPlus = faUserPlus;
  faLock = faLock;
  url = ''; 
  validando = false;
  router_url = ''; 

  Form: FormGroup;
  submitted = false;
  error = "";
 
  constructor(
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder, 
    private sesion_admin: Sesion_Admin,
    private sesion: Sesion,
    private comunicacion: Comunicacion
  ) { }

  ngOnInit() { 
    this.router_url = this.router.url;
    this.comunicacion.mostrar_menu_administracion(false);
    this.comunicacion.mostrar_menu_admin(false);
    this.comunicacion.mostrar_menu_super_admin(false);
    this.sesion_admin.autenticar().subscribe((data)=>{
      if(data['resultado']=="ok"){
        this.router.navigate(['user/escritorio']);
      }
    });

    this.url = this.router.url;

    this.Form = this.formBuilder.group({
      usuario: ['', [Validators.required]],
      password: ['', [Validators.required, , Validators.minLength(4)]]
    });
  }

  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }

    this.validando = true;
    this.sesion.login(this.Form.value).subscribe((data)=>{
      this.validando = false;
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="datos"){
        this.error = "Datos incorrectos.";
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="conexion"){
        this.error = "Error en la conexión.";
      }else{
        console.log(this.router_url, "/login/"+this.meetingid);
        if(this.router_url == "/login/"+this.meetingid){
          this.router.navigate(['v/'+this.meetingid]);
        }else{
          this.router.navigate(['user/escritorio']);
        }
      }
    });

    // display form values on success
    
  }

}
