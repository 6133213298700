import { Component, OnInit } from '@angular/core';
import { faVideo, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { Sesion_Admin } from '../../api/service/admin/sesion';
import { Comunicacion } from '../../api/tools/comunicacion';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  sesion_cerrada = false;
  faVideo = faVideo;
  faAngleDoubleLeft = faAngleDoubleLeft;

  constructor(
    private sesion_admin : Sesion_Admin,
    private comunicacion: Comunicacion
  ) { }

  ngOnInit() { 
    this.sesion_admin.logout().subscribe((data)=>{
      this.sesion_cerrada = true;
      this.comunicacion.mostrar_menu_administracion(false);
      this.comunicacion.mostrar_menu_admin(false);
    });
  }
 
}
