import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constantes from '../tools/constantes';

@Injectable({
  providedIn: 'root'
})
export class Sesion {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  
  constructor(private httpClient: HttpClient) {
  }

  public login(array_usuario){
    return this.call(array_usuario, "sesion/login");
  }

  private call(data, metodo){
    return this.httpClient.post(
      Constantes.apiserver+metodo, 
      data, 
      this.httpOptions
    );
  }

}