import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faVideo, faTimesCircle, faTrashAlt, faEdit, faExclamation, faAngleDoubleLeft, faSignInAlt, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

import * as Constantes from '../../api/tools/constantes';
 
@Component({
  selector: 'app-sala',
  templateUrl: './sala.component.html',
  styleUrls: ['./sala.component.scss']
})
export class SalaComponent implements OnInit {
  faVideo = faVideo;
  faTimesCircle = faTimesCircle;
  faTrashAlt = faTrashAlt;
  faSignInAlt = faSignInAlt;
  faCopy = faCopy;
  faEdit = faEdit;
  faCheck = faCheck;
  id_videoconferencia = this.activatedrouter.snapshot.paramMap.get('id_videoconferencia');
  id_correcto = true;
  sin_acceso = false;
  mostrar = false;
  error = "";
  v : any; 
  url = "";
  tiempo_agregado = 0;
  eliminacion_error = '';
  url_actual = false;
  faExclamation = faExclamation;
  faAngleDoubleLeft = faAngleDoubleLeft;
  copiado = false;

  constructor(
    private router: Router,
    private activatedrouter: ActivatedRoute,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin,
    private modalService: NgbModal
  ) { }

  cargar_informacion(){
    this.videoconferencia_admin.get(this.id_videoconferencia).subscribe((data)=>{
      if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
        this.sin_acceso = true;
      }else if(data['resultado']=="not ok"){
        this.error = "Parámetros no validos."
        this.sin_acceso = true;
      }else if(data['resultado']=="ok"){
        this.mostrar = true;
        this.v = data['v'];
        if(data['url'] == 1){ 
          this.url = this.videoconferencia_admin.entrar_videoconferencia_moderador(this.v['id']);
        }else{
          setTimeout(()=>{
            this.cargar_informacion()
          }, Constantes.frecuencia_actualizacion * 1000);
          this.tiempo_agregado = data['tiempo_agregado'];
        }
        if(this.router.url=="/user/videoconferencia_editada/"+this.v['id']){
          this.url_actual = true;
        }
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }
    });
  }

  copyText(){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.v.invitar;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copiado = true;
    setTimeout(() => this.copiado=false, 2000);
  }

  ngOnInit() {
    if(Number.isInteger(Number(this.id_videoconferencia))){
      this.cargar_informacion();
    }else{
      this.id_correcto = false;
    }
  }
 
  open(content_eliminar) {
    this.eliminacion_error = '';
    this.modalService.open(content_eliminar, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		if(result=='si'){
			this.videoconferencia_admin.eliminar(this.id_videoconferencia).subscribe((data)=>{
				if(data['resultado']=="not login"){
					this.error = "No has iniciado sesión.";
					this.router.navigate(['']);
				}else if(data['resultado']=="not admin"){
					this.error = "Tu usuario no es administrador.";
				}else if(data['resultado']=="error"){
					this.eliminacion_error = "Error en la solicitud.";
				}else if(data['resultado']=="not ok"){
					this.eliminacion_error = "Parámetros no validos.";
				}else if(data['resultado']=="not eliminado"){
					this.eliminacion_error = "No eliminado, es posible que la videoconferencia ya haya iniciado o finalizado.";
				}else if(data['resultado']=="ok"){
					this.router.navigate(['user/eliminado']);
				}
			});
        }
    });
  }

}
