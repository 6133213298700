import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';

import { faVideo, faTimesCircle, faAngleDoubleLeft, faEye, faTools} from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-ver-videoconferencia',
  templateUrl: './ver-videoconferencia.component.html',
  styleUrls: ['./ver-videoconferencia.component.scss']
})
export class VerVideoconferenciaComponent implements OnInit {
  faVideo = faVideo;
  faTimesCircle = faTimesCircle;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faEye = faEye;
  faTools = faTools;

  id_videoconferencia = this.activatedrouter.snapshot.paramMap.get('id_videoconferencia');
  id_correcto = true;
  sin_acceso = false;
  mostrar = false;
  error = "";
  v : any;

  constructor(
    private router: Router,
    private activatedrouter: ActivatedRoute,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin
  ) { }

  cargar_informacion(){
    this.videoconferencia_admin.ver_videoconferencia(this.id_videoconferencia).subscribe((data)=>{
      if(data['resultado']=="not login"){
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
        this.sin_acceso = true;
      }else if(data['resultado']=="not ok"){
        this.error = "Parámetros no validos."
        this.sin_acceso = true;
      }else if(data['resultado']=="ok"){
        this.mostrar = true;
        this.v = data['v'];
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }
    });
  }

  ngOnInit() {
    if(Number.isInteger(Number(this.id_videoconferencia))){
      this.cargar_informacion();
    }else{
      this.id_correcto = false;
    }
  }

}
