import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faVideo, faTimesCircle, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

import { Comunicacion } from '../../api/tools/comunicacion';
import {Videoconferencia_Admin} from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-programar',
  templateUrl: './programar.component.html',
  styleUrls: ['./programar.component.scss']
})
export class ProgramarComponent implements OnInit {
  faVideo = faVideo;
  faTimesCircle = faTimesCircle;
  faCheckDouble = faCheckDouble;
  Form: FormGroup;
  submitted = false;
  error = "";
  fecha_hora_actual;
  habilitar_iniciar_ahora = false;
  ocultar_fecha_hora = false;
  error_fecha_hora = false;
  concurrencia = false;
  concurrencia_max = 0;
  max_sala = 0;
  usuarios_min = false;
  no_disponible = false;
  cantidad_videoconferencias = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin
  ) { }

  get f() { return this.Form.controls; }


  private set_fecha_hora_actual(){
    var d = new Date();
    var m = d.getMinutes();
    var h = d.getHours();
    var dia = d.getDate();
    if(m>45){
      m = 0;
      h++;
      if(h>23){
        h=0;
        dia++;
      }
    }else if(m>15){
      m = 30;
    }else{
      m = 0;
    }
    return new Date(d.getFullYear(), d.getMonth(), dia, h, m);
  }

  ngOnInit() {
    this.videoconferencia_admin.disponibilidad().subscribe((data)=>{
      if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }
      if(data['resultado']=="ok"){
        this.max_sala = data['num_max_audiencia_sala'];
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.router.navigate(['user/escritorio']);
          this.comunicacion.mostrar_menu_admin(false);
        }
      } 
    });
    this.habilitar_iniciar_ahora = true;
    this.fecha_hora_actual = this.set_fecha_hora_actual();
    this.Form = this.formBuilder.group({
      nombre: [ "", [Validators.required, Validators.minLength(4)]],
      iniciar_ahora: ["No", null],
      fecha_hora: ["", null],
      duracion: ["", [Validators.required, Validators.min(30), Validators.max(480)]],
      usuarios: ["", [Validators.required]]
    });
  }

  iniciar_ahora_cambio(){
    if(this.Form.get('iniciar_ahora').value=="Si")
      this.ocultar_fecha_hora = true;
    else
      this.ocultar_fecha_hora = false;
  }

  onSubmit() {
    this.submitted = true;

    this.error_fecha_hora = false;
    if(this.Form.get('iniciar_ahora').value == "No" && this.Form.get('fecha_hora').value==""){
      this.error_fecha_hora = true;
    }else if(this.Form.get('iniciar_ahora').value == "Si"){
      this.Form.get('fecha_hora').setValue(this.set_fecha_hora_actual());
    }
    
    this.usuarios_min = false;
    if(parseInt(this.Form.get('usuarios').value)<2){
      this.usuarios_min = true;
    }
    
    // stop here if form is invalid
    if (this.Form.invalid || this.error_fecha_hora || this.usuarios_min) {
        return;
    }

    this.videoconferencia_admin.add(this.Form.value).subscribe((data)=>{
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="alterado"){
        this.error = "Valor de audiencia no válido."; 
      }else if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="not admin"){
        this.error = "Tu usuario no es administrador.";
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="concurrencia"){
        this.concurrencia = true;
        this.concurrencia_max = data['concurrencia_max'];
        this.error = "Error en concurrencia.";
      }else{
        this.router.navigate(['user/programado']);
      }
    });
    
  }

}
