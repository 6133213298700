import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHistory, faTools, faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html', 
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  faHistory = faHistory;
  faTools = faTools;
  faTimesCircle = faTimesCircle;
  faSearch = faSearch;
  videoconferencias = [];
  error = '';
  pagina = 0;
  mostrar_videoconferencias = true;
  buscar = "";
  cargando = 0;

  Form: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private comunicacion: Comunicacion,
    private formBuilder: FormBuilder, 
    private videoconferencia_admin: Videoconferencia_Admin
  ) { }

  limpiar(){
    this.videoconferencias = [];
    this.pagina = 0;
    this.buscar = "";
    this.mostrar_videoconferencias = true;
    this.error = "";
    this.Form.get("buscar").setValue("");
    this.submitted = false;

    this.cargar_mas();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }

    this.videoconferencias = [];
    this.pagina = 0;
    this.buscar = this.Form.get('buscar').value;
    this.mostrar_videoconferencias = true;
    this.error = "";

    this.cargar_mas();
  }

  cargar_mas(){
    this.cargando = 1;
    this.videoconferencia_admin.historial(this.pagina, this.buscar).subscribe((data)=>{
      this.cargando = 0;
      if(data['resultado']=="not login"){
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="ok"){
        if(data['cantidad']>0){
          if(this.pagina == 0){
            this.videoconferencias = data['programadas'];
          }else {
            var programadas = data['programadas'];
            for(var i=0; i < programadas.length; i++){
              this.videoconferencias.push(programadas[i]);
            }
          }
          this.pagina++;
        }else{
          if(this.pagina==0){
            this.error = "No hay videoconferencias que mostrar.";
            this.mostrar_videoconferencias = false;
          }else{
            this.error = "No existen más videoconferencias que mostrar.";
            this.mostrar_videoconferencias = false;
          }
        }
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }
    });
  }

  get f() { return this.Form.controls; }

  ngOnInit() {
    this.cargar_mas();

    this.Form = this.formBuilder.group({
      buscar: ['', [Validators.required, , Validators.minLength(4)]]
    });
  }

}
