import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constantes from '../../tools/constantes';

@Injectable({
  providedIn: 'root'
})
export class Videoconferencia_Admin {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  
  constructor(private httpClient: HttpClient) {
  }
 
  public add(array_videoconferencia){
    return this.call(array_videoconferencia, "admin/videoconferencia/add");
  }

  public disponibilidad(){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/disponibilidad/");
  } 

  public programadas_mes(year, mes){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/programadas_mes/"+year+"/"+mes);
  }

  public programadas_dia(year, mes, dia){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/programadas_dia/"+year+"/"+mes+"/"+dia);
  }

  public recuperar(){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/recuperar/");
  }

  public historial(pagina, buscar){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/historial/"+pagina+"/"+buscar);
  }

  public reporte(pagina, buscar){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/historial/"+pagina+"/"+buscar);
  }

  public get(id_videoconferencia){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/get/"+id_videoconferencia);
  }

  public ver_videoconferencia(id_videoconferencia){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/ver_videoconferencia/"+id_videoconferencia);
  }

  public ver_encuesta(id_videoconferencia, id_encuesta){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/ver_encuesta/"+id_videoconferencia+"/"+id_encuesta);
  }

  public finalizar(secret, meetingID, recordingmarks){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/finalizar/"+secret+"/"+meetingID+"/"+recordingmarks);
  }

  public entrar_videoconferencia_moderador(id){
    return Constantes.apiserver+"admin/videoconferencia/crear/"+id;
  }

  public eliminar(id_videoconferencia){
    return this.httpClient.get(Constantes.apiserver+"admin/videoconferencia/eliminar/"+id_videoconferencia);
  }

  public editar(array_videoconferencia, id_videoconferencia){
    return this.call(array_videoconferencia, "admin/videoconferencia/editar/"+id_videoconferencia);
  }

  private call(data, metodo){
    return this.httpClient.post(
      Constantes.apiserver+metodo, 
      data, 
      this.httpOptions
    );
  }

}