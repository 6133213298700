import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router} from '@angular/router';
import { faVideo, faThumbsUp, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../api/tools/comunicacion';
import { Sesion_Admin } from '../api/service/admin/sesion';
import { Videoconferencia } from '../api/service/videoconferencia';

@Component({
  selector: 'app-finalizado',
  templateUrl: './finalizado.component.html',
  styleUrls: ['./finalizado.component.scss']
})
export class FinalizadoComponent implements OnInit {
  meetingid = this.activatedrouter.snapshot.paramMap.get('meetingid');
  regresar = "";
  faVideo = faVideo;
  faThumbsUp = faThumbsUp;
  faAngleDoubleLeft = faAngleDoubleLeft;

  constructor(
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private comunicacion: Comunicacion,
    private sesion_admin: Sesion_Admin,
    private videoconferencia: Videoconferencia
  ) { }

  ngOnInit() {
    this.videoconferencia.finalizar_sesion_videoconferencia(this.meetingid).subscribe((data)=>{
      if(data['resultado']=="ok"){
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
        this.regresar = "/user/escritorio";
      }else if(data['resultado']=="error"){
        this.regresar = "";
        this.comunicacion.mostrar_menu_administracion(false);
        this.comunicacion.mostrar_menu_admin(false);
      }else if(data['resultado']=="not ok"){
        this.regresar = "";
        this.comunicacion.mostrar_menu_administracion(false);
        this.comunicacion.mostrar_menu_admin(false);
      }
    });
  }

}
