import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../../api/tools/comunicacion';

import { Sesion_Admin } from '../../api/service/admin/sesion';
import { Usuario_Admin } from '../../api/service/admin/usuario';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  Form: FormGroup;
  submitted = false;
  error = "";
  mostrar = false;
  faUserEdit = faUserEdit;
  perfil_editado = false;

  constructor(
    private sesion_admin : Sesion_Admin,
    private router: Router,
    private formBuilder: FormBuilder,
    private usuario_admin : Usuario_Admin,
    private comunicacion: Comunicacion
  ) { } 
  
  get f() { return this.Form.controls; }

  ngOnInit() {
    this.sesion_admin.recuperar().subscribe((data)=>{
      if(data["resultado"]=="ok"){
        this.mostrar = true;
        this.Form = this.formBuilder.group({
          unidad_academica: [{value: data["unidad_academica"], disabled: true}, Validators.required],
          nombre: [ data["nombre"], [Validators.required, Validators.minLength(3)]],
          usuario: [ data["usuario"], [Validators.required, Validators.minLength(3)]],
          correo: [ data["correo"], [Validators.required, Validators.email]]
        });
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }else{ 
        this.router.navigate(['']);
      }
    });
  }
 
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }

    this.perfil_editado = false;
    this.usuario_admin.editar(this.Form.value).subscribe((data)=>{
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else{
        this.perfil_editado = true;
      }
    });

    // display form values on success
    
  }

}
