import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faUserPlus, faAngleDoubleLeft,faUsersCog, faUserEdit } from '@fortawesome/free-solid-svg-icons';

import { Sesion_Admin } from '../../api/service/admin/sesion';
import { Usuario_Superadmin } from '../../api/service/superadmin/usuario';

import { Comunicacion } from '../../api/tools/comunicacion';
import { MustMatch } from '../../api/tools/herramientas';

import { Usuario_Admin } from '../../api/service/admin/usuario';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit {
  Form: FormGroup;
  mostrar = false;
  perfil_editado = false;
  url;
  id_usuario = this.activatedrouter.snapshot.paramMap.get('id_usuario');
  url_back = "/admin/administrar_usuario/"+this.id_usuario;
  faUsersCog = faUsersCog;
  faUserPlus = faUserPlus;
  faUserEdit = faUserEdit;
  faAngleDoubleLeft = faAngleDoubleLeft;
  submitted = false;
  error = "";

  constructor(
    private formBuilder: FormBuilder, 
    private usuario_superadmin: Usuario_Superadmin,
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private comunicacion: Comunicacion,
    private sesion_admin: Sesion_Admin,

    private usuario_admin : Usuario_Admin
  ) { }

  get f() { return this.Form.controls; }

  ngOnInit() {
    this.usuario_admin.get(this.id_usuario).subscribe((data)=>{
      if(data["resultado"]=="ok"){
        this.mostrar = true;
        this.Form = this.formBuilder.group({
          nombre: [ data["v"].nombre, [Validators.required, Validators.minLength(3)]],
          usuario: [ data["v"].usuario, [Validators.required, Validators.minLength(3)]],
          correo: [ data["v"].correo, [Validators.required, Validators.email]],
          password: ['', [ Validators.minLength(4)]],
          confirmpassword: ['']
        }, {
          validator: MustMatch('password', 'confirmpassword')
        });
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
        }else{
          this.comunicacion.mostrar_menu_admin(false);
        }
      }else{ 
        this.router.navigate(['']);
      }
    });
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }

    this.perfil_editado = false;
    this.usuario_admin.editarUsuarios(this.id_usuario,this.Form.value).subscribe((data)=>{
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else{
        // this.perfil_editado = true;
        this.router.navigate(['admin/usuario_editado/'+this.id_usuario]);
      }
    });

    // display form values on success
    
  }


}
