import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faUserPlus, faAngleDoubleLeft,faUsersCog } from '@fortawesome/free-solid-svg-icons';

import { Sesion_Admin } from '../../api/service/admin/sesion';
import { Usuario_Superadmin } from '../../api/service/superadmin/usuario';

import { Comunicacion } from '../../api/tools/comunicacion';
import { MustMatch } from '../../api/tools/herramientas';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.scss']
})
export class NuevoUsuarioComponent implements OnInit {
  url;
  faUsersCog = faUsersCog;
  faUserPlus = faUserPlus;
  faAngleDoubleLeft = faAngleDoubleLeft;
  registerForm: FormGroup;
  submitted = false;
  error = "";

  constructor(
    private formBuilder: FormBuilder, 
    private usuario_superadmin: Usuario_Superadmin,
    private router: Router,
    private comunicacion: Comunicacion,
    private sesion_admin: Sesion_Admin
  ) { }

  ngOnInit() {
    this.url = this.router.url;

    this.sesion_admin.autenticar().subscribe((data)=>{
      this.comunicacion.mostrar_menu_administracion(true);
      if(data['rol_user']=='administrador'){
        this.comunicacion.mostrar_menu_admin(true);
      }else if(data['rol_user']=='usuario'){
        this.comunicacion.mostrar_menu_admin(false);
      }else{
        this.router.navigate(['']);
      }
    });

    this.registerForm = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      correo: ['', [Validators.required, Validators.email]],
      usuario: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, , Validators.minLength(4)]],
      confirmpassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmpassword')
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    this.usuario_superadmin.add_admin(this.registerForm.value).subscribe((data)=>{
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="usuario"){
        this.error = "El usuario ya existe.";
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="not admin"){
        this.error = "Tu usuario no es administrador.";
      }else if(data['resultado']=="not login"){
        this.error = "Tu usuario no es administrador.";
      }else{
        this.router.navigate(['admin/usuario_agregado']);
      }
    });

    // display form values on success
    
  }

}
