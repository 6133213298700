import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';
import { faCheckDouble, faTimesCircle, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-ver-programacion',
  templateUrl: './ver-programacion.component.html',
  styleUrls: ['./ver-programacion.component.scss']
})
export class VerProgramacionComponent implements OnInit {
  year = this.activatedrouter.snapshot.paramMap.get('year');
  mes = this.activatedrouter.snapshot.paramMap.get('mes');
  dia = this.activatedrouter.snapshot.paramMap.get('dia');
  error = "";
  meses = [];
  mes_texto = "";
  informacion = [];
  total_disponibilidad = 0;
  faCheckDouble = faCheckDouble;
  faTimesCircle = faTimesCircle;
  faAngleDoubleLeft = faAngleDoubleLeft;
  index = 0;

  constructor(
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin,
    private activatedrouter: ActivatedRoute,
    private router: Router
  ) { }

  load_months(){
		this.meses.push("Enero");
		this.meses.push("Febrero");
		this.meses.push("Marzo");
		this.meses.push("Abril");
		this.meses.push("Mayo");
		this.meses.push("Junio");
		this.meses.push("Julio");
		this.meses.push("Agosto");
		this.meses.push("Septiembre");
		this.meses.push("Octubre");
		this.meses.push("Noviembre");
    this.meses.push("Diciembre");
  }

  ngOnInit() {
    if(isNaN(Number(String(this.year))) || isNaN(Number(String(this.mes))) || isNaN(Number(String(this.dia)))){
      this.router.navigate(['admin/programacion']);
    }else{
      this.load_months();
      this.mes_texto = this.meses[this.mes];
      this.error = "";
      this.videoconferencia_admin.programadas_dia(this.year, (Number.parseInt(String(this.mes))+Number.parseInt(String(1))), this.dia).subscribe((data)=>{
        if(data['resultado']=="not login"){
          this.router.navigate(['']);
        }else if(data['resultado']=="error"){
          this.error = "Error en la solicitud.";
        }else if(data['resultado']=="ok"){
          this.informacion = data['informacion'];
          this.index = data['index'];
          this.total_disponibilidad = data['total_disponibilidad'];
          this.comunicacion.mostrar_menu_administracion(true);
          if(data['rol_user']=='administrador'){
            this.comunicacion.mostrar_menu_admin(true);
          }else{
            this.comunicacion.mostrar_menu_admin(false);
          }
        }
      });
    }
  }

}
