import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constantes from '../tools/constantes';

@Injectable({
  providedIn: 'root'
})
export class Videoconferencia {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  

  constructor(private httpClient: HttpClient) {
  }
  
  public get_meeting(meetingid){
    return this.httpClient.get(Constantes.apiserver+"videoconferencia/get_meeting/"+meetingid);
  }

  public verificar_capacidad(meetingid){
    return this.httpClient.get(Constantes.apiserver+"videoconferencia/verificar_capacidad/"+meetingid);
  }

  public entrar_videoconferencia_password(meetingid, array_invitado){
    return this.call(array_invitado, "videoconferencia/entrar_password/"+meetingid);
  }

  public entrar_videoconferencia_token(token){
    return Constantes.apiserver+"videoconferencia/crear_token/"+token;
  }

  public entrar_videoconferencia(meetingid){
    return Constantes.apiserver+"videoconferencia/entrar/"+meetingid;
  }

  public finalizar_sesion_videoconferencia(meetingid){
    return this.httpClient.get(Constantes.apiserver+"videoconferencia/finalizar_sesion/"+meetingid);
  }

  private call(data, metodo){
    return this.httpClient.post(
      Constantes.apiserver+metodo, 
      data, 
      this.httpOptions
    );
  }

}