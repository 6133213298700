import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHome, faTools, faTrashAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
 
import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

@Component({
  selector: 'app-escritorio',
  templateUrl: './escritorio.component.html',
  styleUrls: ['./escritorio.component.scss']
}) 
export class EscritorioComponent implements OnInit {
  url;
  faHome = faHome;
  faTools = faTools; 
  error = '';
  mostrar_programadas = false; 
  videoconferencias = [];
  constantes: any;
  mostrar_reglas = true;
  faTrashAlt = faTrashAlt;
  faVideo = faVideo;
  usuario_administrador = false;
  usuario_super_administrador = false;
  usuario_normal = false;
  
  
  constructor(
    private router: Router,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin
  ) { }
 
  ngOnInit() {
    this.url = this.router.url;
    this.videoconferencia_admin.recuperar().subscribe((data)=>{
      if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
        this.mostrar_reglas = false;
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
        this.constantes = data['constantes'];
      }else if(data['resultado']=="ok"){
        if(data['cantidad']>0){
          this.mostrar_programadas = true;
          this.videoconferencias = data['programadas'];
        }
        this.comunicacion.mostrar_menu_administracion(true);
        if(data['rol_user']=='administrador'){
          this.comunicacion.mostrar_menu_admin(true);
          this.usuario_administrador = true;
        }else{
          this.usuario_normal = true;
          this.comunicacion.mostrar_menu_admin(false);
        }
        this.constantes = data['constantes'];
      }
    });
  }

}
