import { Component, OnInit, Input } from '@angular/core';
import { faSignOutAlt, faUserEdit, faVideo, faHome, faKey, faUserPlus, faHistory, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { Comunicacion } from '../api/tools/comunicacion';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faSignOutAlt = faSignOutAlt;
  faUserEdit = faUserEdit;
  faVideo = faVideo;
  faHome = faHome;
  faKey = faKey;
  faUserPlus = faUserPlus;
  faHistory = faHistory;
  faChartBar = faChartBar;
  navbarCollapsed = false;

  menu_administracion = false;
  menu_admin = false;
  menu_super_admin = false;

  constructor(
    private comunicacion: Comunicacion
  ) { }

  ngOnInit() {
    this.comunicacion.change.subscribe(mensaje => {
      if(mensaje['evento']==1){
        this.menu_administracion = mensaje['valor'];
      }else if(mensaje['evento']==2){
        this.menu_admin = mensaje['valor'];
      }else if(mensaje['evento']==3){
        this.menu_super_admin = mensaje['valor'];
      }
    });
  }

}
