import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Comunicacion } from '../../api/tools/comunicacion';
import { Videoconferencia_Admin } from '../../api/service/admin/videoconferencia';

import { faTimesCircle, faEdit, faAngleDoubleLeft, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-editar-videoconferencia',
  templateUrl: './editar-videoconferencia.component.html',
  styleUrls: ['./editar-videoconferencia.component.scss']
})
export class EditarVideoconferenciaComponent implements OnInit {
  id_videoconferencia = this.activatedrouter.snapshot.paramMap.get('id_videoconferencia');
  id_correcto = true;
  sin_acceso = false;
  mostrar = false;
  error = "";
  v : any;
  Form: FormGroup;
  faTimesCircle = faTimesCircle;
  faEdit = faEdit;
  habilitar_iniciar_ahora = false;
  fecha_hora_actual;
  ocultar_fecha_hora = false;
  submitted = false;
  error_fecha_hora = false;
  concurrencia = false;
  concurrencia_max = 0;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faCheckDouble = faCheckDouble;
  max_sala = 0;
  usuarios_max = false;
  usuarios_min = false;

  constructor(
    private formBuilder: FormBuilder,
    private comunicacion: Comunicacion,
    private videoconferencia_admin: Videoconferencia_Admin,
    private activatedrouter: ActivatedRoute,
    private router: Router
  ) { }
  
  get f() { return this.Form.controls; }

  private set_fecha_hora_actual(){
    var d = new Date();
    var m = d.getMinutes();
    var h = d.getHours();
    var dia = d.getDate();
    if(m>45){
      m = 0;
      h++;
      if(h>23){
        h=0;
        dia++;
      }
    }else if(m>15){
      m = 30;
    }else{
      m = 0;
    }
    return new Date(d.getFullYear(), d.getMonth(), dia, h, m);
  }

  iniciar_ahora_cambio(){
    if(this.Form.get('iniciar_ahora').value=="Si")
      this.ocultar_fecha_hora = true;
    else
      this.ocultar_fecha_hora = false;
  }

  ngOnInit() {
    if(Number.isInteger(Number(this.id_videoconferencia))){
      this.videoconferencia_admin.get(this.id_videoconferencia).subscribe((data)=>{
        if(data['resultado']=="not login"){
          this.error = "No has iniciado sesión.";
          this.router.navigate(['']);
        }else if(data['resultado']=="error"){
          this.error = "Error en la solicitud.";
          this.sin_acceso = true;
        }else if(data['resultado']=="not ok"){
          this.error = "Parámetros no validos."
          this.sin_acceso = true;
        }else if(data['resultado']=="ok"){
          this.mostrar = true;
          this.v = data['v'];
          this.max_sala = data['num_max_audiencia_sala'];
          
          var f= this.v["fecha_inicio"];
          var f = f.split(" ");
          var d = f[0].split("-");
          var t = f[1].split(":");

          this.fecha_hora_actual = this.set_fecha_hora_actual();
          this.habilitar_iniciar_ahora = true;
          this.Form = this.formBuilder.group({
            nombre: [ this.v['nombre'], [Validators.required, Validators.minLength(4)]],
            iniciar_ahora: ["No", null],
            fecha_hora: [new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1])), [Validators.required]],
            duracion: [this.v['minutos'], [Validators.required, Validators.min(30), Validators.max(480)]],
            usuarios: [this.v['usuarios'], [Validators.required]]
          });

          this.comunicacion.mostrar_menu_administracion(true);
          if(data['rol_user']=='administrador'){
            this.comunicacion.mostrar_menu_admin(true);
          }else{
            this.comunicacion.mostrar_menu_admin(false);
          }
        }
      });
    }else{
      this.id_correcto = false;
    }
  }

  onSubmit() {
    this.submitted = true;

    this.error_fecha_hora = false;
    if(this.Form.get('iniciar_ahora').value == "No" && this.Form.get('fecha_hora').value==""){
      this.error_fecha_hora = true;
    }else if(this.Form.get('iniciar_ahora').value == "Si"){
      this.Form.get('fecha_hora').setValue(this.set_fecha_hora_actual());
    }

    this.usuarios_min = false;
    if(parseInt(this.Form.get('usuarios').value)<2){
      this.usuarios_min = true;
    }
    this.usuarios_max = false;
    if(parseInt(this.Form.get('usuarios').value)>this.max_sala){
      this.usuarios_max = true;
    }

    // stop here if form is invalid
    if (this.Form.invalid || this.error_fecha_hora || this.usuarios_min || this.usuarios_max) {
        return;
    }

    this.videoconferencia_admin.editar(this.Form.value, this.id_videoconferencia).subscribe((data)=>{
      if(data['resultado']=="not ok"){
        this.error = "Por favor completa el formulario.";
      }else if(data['resultado']=="not admin"){
        this.error = "Tu usuario no es administrador.";
      }else if(data['resultado']=="alterado"){
        this.error = "Valor de audiencia no válido.";
      }else if(data['resultado']=="not login"){
        this.error = "No has iniciado sesión.";
        this.router.navigate(['']);
      }else if(data['resultado']=="error"){
        this.error = "Error en la solicitud.";
      }else if(data['resultado']=="concurrencia"){
        this.concurrencia = true;
        this.concurrencia_max = data['concurrencia_max'];
        this.error = "Error en concurrencia.";
      }else{
        this.router.navigate(['/user/videoconferencia_editada/'+this.id_videoconferencia]);
      }
    });
    
  }

}
